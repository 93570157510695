.text1 {
    display: flex;
    justify-content: center;
    align-items: center;
     /* Adjust the height as needed */
  }
  .text-center1 {
    position: relative;
    width: 100%;
    /* margin: 12px; */
     /* Adjust the height as needed */
  }
  
  .centered-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f0f0f0;
    padding: 20px;
  }
  
  .best {
    font-weight: 700;
    cursor: auto;
    background: #15803D;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .typewriter {
    width: 21.5ch;
    white-space: nowrap;
    overflow: hidden;
    /* border-right: 4px solid #; */
    animation: cursor 1s step-start infinite,
      text 5s steps(18) alternate infinite;
  }
  
  @keyframes cursor {
    0%, 100% {
      border-color: #212121;
    }
  }
  
  @keyframes text {
    0% {
      width: 0;
    }
    100% {
      width: 14.5ch;
    }
  }
  

  /* animation charcter */
  .animate-charcter {
    font-weight: 700;
    text-transform: uppercase;
    background-image: linear-gradient(-225deg,
    #FF5F00 0%,
    #22C55E 29%,
    #FF5F00 67%,
    #166534 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: var(--white);
    background-clip: text;
    /* text-fill-color: transparent; */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    /* font-size: 190px; */
  }

  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }

  .truncate {
    white-space: unset;
}
.bg-light {
    background: aliceblue;
}